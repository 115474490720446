<template>
  <div class="community">
    <template v-for="(item, index) in friend_circles">
      <!-- {{ item }} -->
      <HandmadeCircle
        ref="handcircle"
        class="circle-item"
        :cur_user_obj="item"
        @click_reply_btn="click_reply_btn"
        @view_replay="view_replay"
        @get_friend_cycle_index="get_friend_cycle_index"
      ></HandmadeCircle>
      <!-- 每个朋友圈的下面有一段分割线，如果是当前页面最后一个朋友圈，则下面没有分割线 -->
      <div v-if="index + 1 < friend_circles.length" class="border"></div>
    </template>
    <CommentReplyBox
      :comment_data="this.click_comment_data.cur"
      @close_reply_box="close_reply_box"
      @add_reply="add_reply"
      v-if="is_replyBox_active"
      :class="{
        front: is_replyBox_active,
        back: !is_replyBox_active,
      }"
    ></CommentReplyBox>
    <ChildComment
      :comment_data="child_comment"
      @close_reply_box="close_reply_box"
      @click_reply_btn="click_reply_btn"
      @close_child_comment_box="close_child_comment_box"
      @get_friend_cycle_index="get_friend_cycle_index"
      v-if="is_show_reply"
    ></ChildComment>
  </div>
</template>

<script type="text/babel">
import { Loading } from "element-ui";
import HandmadeCircle from "./HandmadeCircle.vue";
import ChildComment from "./ChildComment.vue";
import CommentReplyBox from "./CommentReplyBox.vue";
import { getUserInfo, get_token } from "../common/common.js";
export default {
  name: "community",
  data() {
    return {
      friend_circles: [],
      is_replyBox_active: false,
      click_comment_data: {},
      is_show_reply: false,
      comment_data: {},
      child_comment: {},
      cur_friend_circle_index: 0,
      page_no: 1,
      init_height: 0,
      total_cnt: 0,
      scrollInterval: null,
      loadingInstance: null,
    };
  },
  components: { HandmadeCircle, ChildComment, CommentReplyBox },
  methods: {
    get_cur_user() {
      return getUserInfo();
    },

    message_box(type, message) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
        customClass: "messageIndex",
      });
    },

    async get_friend_cicle_list(start_page) {
      // console.log(
      //   "调用朋友圈函数拉！！！",
      //   this.get_cur_user(),
      //   start_page,
      //   this.page_no
      // );
      // 开启loading加载效果
      this.loadingInstance = Loading.service({ fullscreen: true });
      try {
        const token = get_token("token");

        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/get_friend_cicle", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: {
            start_page: start_page == null ? 1 : start_page,
            page_size: 10,
          },
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/get_friend_cicle",
        //   {
        //     headers: {
        //       "Content-Type": "application/json",
        //     },
        //     data: {
        //       user_nick: this.get_cur_user().nick_name,
        //       start_page: start_page == null ? 1 : start_page,
        //       page_size: 10,
        //     },
        //     // data,
        //   }
        // );

        if (response.data.status == 200) {
          // 在这里编写需要延迟执行的代码
          const res = response.data.main;

          this.total_cnt = response.data.total;
          // console.log("朋友圈总条数: ", this.total_cnt);
          for (let i = 0; i < res.length; i++) {
            this.friend_circles.push(res[i]);
          }

          const cur_page_no = start_page == null ? 1 : start_page;
          // console.log("监控前: ", Math.ceil(this.total_cnt / 10), cur_page_no);
          if (Math.ceil(this.total_cnt / 10) > this.page_no) {
            this.mornite_scroll();
          }

          return;
        }
        //   console.log(response);
      } catch (e) {
        console.log(e);
      } finally {
        // 清除loading加载效果
        this.loadingInstance.close();
      }
    },

    async get_child_comment(comment_id) {
      try {
        const token = get_token("token");
        // console.log("获取积分");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/get_comments_by_id", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: {
            comment_id: comment_id,
          },
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/get_comments_by_id",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data: {
        //       comment_id: comment_id,
        //       user_nick: this.get_cur_user().nick_name,
        //     },
        //     // data,
        //   }
        // );

        // console.log("length", response.data.main, response.data.main.length);
        if (response.data.status != 200) {
          this.message_box("error", "该用户还没有在本站注册，请先注册");
        } else {
          return response.data.main;
        }

        //   console.log(response);
      } catch (e) {
        console.log(e);
      }
    },

    // 关闭回复框
    close_reply_box() {
      // console.log("父组件:close_reply_box");
      this.is_replyBox_active = false;
    },

    // 点击子评论中的评论按钮，弹出回复框
    click_reply_btn(comment_date) {
      // this.login_btn_click_event();
      this.click_comment_data = comment_date;
      // console.log(
      //   "click reply btn ",
      //   this.click_comment_data,
      //   this.friend_circles
      // );
      // this.$refs.replybox.style.zIndex = 100;
      this.is_replyBox_active = true;
      // this.$refs.
    },

    async view_replay(item) {
      const data = await this.get_child_comment(item.comment_id);
      // console.log("community组件中 ", item, data, data[0]);
      this.child_comment = {
        avatar_url: item.avatar_url,
        comment_id: item.comment_id,
        content: item.content,
        is_like: item.is_like,
        like_cnt: item.like_cnt,
        nick_name: item.nick_name,
        publish_time: item.publish_time,
        target_id: item.target_id,
      };

      if (!("children" in this.child_comment)) {
        this.$set(this.child_comment, "children", data);
      } else {
        this.child_comment.children = [];
        for (let i = 0; i < data.length; i++) {
          this.child_comment.children.unshift(data[i]);
        }
      }

      // if (!("children" in this.cur_click_comment)) {
      //   this.$set(this.cur_click_comment, "children", data);
      // } else {
      //   this.cur_click_comment.children = [];
      //   for (let i = 0; i < data.length; i++) {
      //     this.cur_click_comment.children.unshift(data[i]);
      //   }
      // }
      this.is_show_reply = true;
      //隐藏主页面的滚动条
      document.body.style.overflow = "hidden";
    },

    close_child_comment_box() {
      this.is_show_reply = false;
      //隐藏主页面的滚动条
      document.body.style.overflow = "scroll";
      this.child_comment = {};
      // this.comment_data = null;
    },

    add_reply(reply) {
      // console.log("调用cummunity组建add_reply: ", this.child_comment);
      // 为回复展开页面更新新回复的评论
      if ("children" in this.child_comment) {
        this.child_comment.children.unshift(reply);
      } else {
        this.$set(this.child_comment, "children", [reply]);
      }

      // 更新朋友圈界面更新的回复信息
      if ("par" in this.click_comment_data) {
        if (!("children" in this.click_comment_data.par)) {
          this.$set(this.click_comment_data.par, "children", [reply]);
        } else {
          this.click_comment_data.par.children.unshift(reply);
        }
      } else {
        if (!("children" in this.click_comment_data.cur)) {
          this.$set(this.click_comment_data.cur, "children", [reply]);
        } else {
          this.click_comment_data.cur.children.unshift(reply);
        }
      }
    },

    // 获取当前朋友圈的下标
    get_friend_cycle_index(id) {
      const index = this.friend_circles.findIndex((item) => item.id === id);
      this.cur_friend_circle_index = index == null ? 0 : index;
      // console.log(
      //   "获取当前选择的朋友圈的下标: ",
      //   this.cur_friend_circle_index,
      //   this.friend_circles[this.cur_friend_circle_index]
      // );
    },

    judge_scroll_is_bottom() {
      // console.log(
      //   "监测滚共条: ",
      //   window.pageYOffset + window.innerHeight,
      //   document.documentElement.scrollHeight
      // );
      const cur_height = window.pageYOffset + window.innerHeight;
      if (
        cur_height >= document.documentElement.scrollHeight &&
        cur_height > this.init_height
      ) {
        // console.log("滚动条达到底部拉~~~~~~~~~~~~~~");
        // clearInterval(this.scrollInterval);
        this.init_height = cur_height;
        return true;
      } else {
        return false;
      }
    },

    mornite_scroll() {
      clearInterval(this.scrollInterval);

      // 监听滚动条的是否到底拉
      this.scrollInterval = setInterval(() => {
        // console.log("检测内部");
        // this.position += speed;

        const res = this.judge_scroll_is_bottom();
        // console.log("res的值: ", res, this.total_cnt, this.page_no);

        if (res) {
          this.page_no = this.page_no + 1;
          clearInterval(this.scrollInterval);
          if (Math.ceil(this.total_cnt / 10) >= this.page_no) {
            this.get_friend_cicle_list(this.page_no);
          }
        }
      }, 1000 / 60);
    },
  },
  mounted() {
    this.get_friend_cicle_list();
    // console.log("this.firend ", this.friend_circles);

    this.init_height = window.pageYOffset + window.innerHeight;

    this.mornite_scroll();
  },

  computed: {
    cur_click_comment: {
      get() {
        return "par" in this.click_comment_data
          ? this.click_comment_data.par
          : this.click_comment_data.cur;
      },

      set(newValue) {
        this.cur_click_comment = newValue;
        // let temp = newValue;
        // console.log("调用computeset: ", newValue);
        // if ("par" in this.click_comment_data) {
        //   console.log("par:!~~~~~~");
        //   this.click_comment_data.par = newValue;
        // } else {
        //   console.log("cur:!~~~~~~");
        //   this.click_comment_data.cur = newValue;
        // }
      },
    },
  },
  watch: {
    friend_circles: {
      handler(newValue, oldValue) {
        // console.log("监测到userobj发生变化了,", newValue, oldValue);
        this.friend_circles = newValue;
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.circle-item {
  margin: 50px 0px;
  box-sizing: border-box;
}

.border {
  width: 600px;
  border: 1px dashed black;
  border-spacing: 100px;
  margin: 0px auto;
}

.back {
  z-index: 1;
}

.front {
  z-index: 100;
}

.messageIndex {
  z-index: 999999 !important;
}
</style>
