<template>
  <!-- 回复框 -->
  <div class="comment-replybox" ref="replybox">
    <div class="bg">
      <div class="content">
        <div class="reply show-box">
          <div class="reply-title">
            <span>回复 @ {{ this.comment_data.nick_name }}</span>
            <div class="box-interact" @click="close_box">
              <i class="el-icon-close"></i>
            </div>
          </div>
          <div class="reply-text">
            <el-input
              type="textarea"
              placeholder="发布你的评论"
              v-model="reply_content"
              :autosize="{ minRows: 5, maxRows: 9 }"
              show-word-limit
              style="border: 1px solid orange; border-radius: 10px"
            >
            </el-input>
          </div>
          <div class="interact">
            <button class="reply-btn" @click="add_reply">回复</button>
          </div>

          <!-- <el-button class="reply-btn" type="primary">回复</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { getUserInfo, get_token } from "../common/common.js";
export default {
  data() {
    return {
      reply_content: "",
      // comment_data: { nick_name: "zhangsan" },
      // is_active: true,
    };
  },
  props: {
    comment_data: { type: Object, required: true },
    // open_reply_box: { type: Function, required: true },
    // close_reply_box: { type: Function, required: false },
  },
  methods: {
    get_cur_user() {
      return getUserInfo();
    },
    async add_reply() {
      // console.log(
      //   "添加回复成功: 添加的回复是 ",
      //   this.reply_content,
      //   this.comment_data
      // );
      const data = {
        content: this.reply_content,
        rate_type: "comment",
        target_id: this.comment_data.target_id,
        nick_name: this.get_cur_user().nick_name,
        parent_id: this.comment_data.comment_id,
      };

      // console.log("data对象: ", data);
      try {
        const token = get_token("token");
        // console.log("获取积分");
        var response = await this.$http({
          method: "put",
          url: "http://www.soutudashi.com/add_reply", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("评论接口返回结果response: friend ", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        if (response.data.status == 200) {
          this.$emit("add_reply", response.data);
          return;
        } else {
          return;
        }
        // }
      } catch (e) {
        console.log(e);
      } finally {
        this.reply_content = "";
        this.close_box();
      }

      //
    },

    close_box() {
      // console.log("关闭弹窗石帆胜丰沙发上");
      this.$emit("close_reply_box");
      // this.is_active = false;
      // this.$refs.replybox.style.display = "none";
    },
  },
};
</script>

<style scoped>
.comment-replybox {
  width: 100%;
  height: 100%;
  position: relative;
}

.bg {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.bg > .content {
  width: 100%;
  height: 100%;
}

.reply {
  /* width: 500px; */
  /* margin-left: auto;
  margin-right: auto; */
  /* position: relative; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-direction: column; */
  width: 600px;
  background-color: white;
  border-radius: 12px;
  margin: 300px auto;
  position: relative;
  top: 0px;
  /* transform: translateY(500) 1s; */
  /* opacity: 0; */
  /* overflow: hidden; */
  /* margin: 350px; */
}

.show-box {
  /* transform: translateX(200);
  transition: transform 1s; */
}
.reply > .reply-title {
  text-align: left;
}

.reply > .reply-title > span {
  line-height: 48px;
  font-size: 20px;
}

.reply > .reply-text {
  padding: 0px 20px 20px 20px;
}

.reply > .reply-text textarea {
  padding: 15px;
}
.reply > .reply-title > span {
  /* font-size: 20px; */
  color: black;
  font: bold;
  font-weight: 700;
  height: 48px;
  margin-left: 20px;
}

.reply > .interact {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
}
.reply > .interact > .reply-btn {
  background-color: orange;
  border-radius: 5px;
  color: white;
  height: 48px;
  width: 150px;
  float: right;
  margin: 0px 20px 20px 0px;
}

.box-interact {
  display: inline-block;
  position: relative;
  float: right;
  margin: 15px 20px 0px 0px;
}

.box-interact > i {
  font-size: 25px;
}
</style>
