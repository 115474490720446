<template>
  <!-- 查看回复时的单独列表 -->
  <div class="child-comment">
    <div class="bg" @click.self="close_box">
      <div class="content">
        <div class="comment-block">
          <!-- 每一个子评论 -->
          <div class="comment-item">
            <!-- 头像 -->
            <img
              class="commener_img"
              :src="cur_comment_data.avatar_url"
              alt=""
            />
            <!-- 评论主体 -->
            <div class="comment-content">
              <div class="text">
                <a class="commener"
                  >{{ cur_comment_data.nick_name }}&nbsp;:&nbsp;</a
                >
                {{ cur_comment_data.content }}
              </div>
              <div class="comment-interactive">
                <div class="left">
                  <span class="time">{{ cur_comment_data.publish_time }}</span>
                </div>
                <div class="right">
                  <a
                    class="rate"
                    @click="click_reply_btn($event, { cur: cur_comment_data })"
                    ><i class="iconfont icon-pinglun1"></i>
                  </a>
                  <a
                    class="dianzan iconfont"
                    @click="like_btn_click($event, cur_comment_data, 4)"
                    ><i
                      class="iconfont"
                      :class="like_btn_style(cur_comment_data)"
                    >
                    </i>
                    &nbsp;<span v-if="cur_comment_data.like_cnt > 0">{{
                      cur_comment_data.like_cnt
                    }}</span></a
                  >
                </div>
              </div>
            </div>
          </div>
          <!-- ----- -->
        </div>
        <div
          class="comment-child"
          v-if="
            'children' in cur_comment_data &&
            cur_comment_data.children.length > 0
          "
        >
          <!-- 分割线 -->
          <div class="divider-line"></div>
          <!-- 子评论信息 -->
          <div
            class="comments"
            v-for="(ele, index) in cur_comment_data.children"
            :key="index"
          >
            <!-- 每一个子评论 -->
            <div class="test">
              <div class="comment-item">
                <!-- 头像 -->
                <img class="commener_img" :src="ele.avatar_url" alt="" />
                <!-- 评论主体 -->
                <div class="comment-content">
                  <div class="text">
                    <a class="commener"
                      >{{ ele.nick_name }}
                      <span v-if="ele.is_other_reply">
                        &nbsp;&nbsp;<span class="statis-text">{{
                          "回复" + ""
                        }}</span>
                        &nbsp;&nbsp;{{ ele.parent_nick_name }}</span
                      >&nbsp;:&nbsp;</a
                    >
                    {{ ele.content }}
                  </div>
                  <div class="comment-interactive">
                    <div class="left">
                      <span class="time">{{ ele.publish_time }}</span>
                    </div>
                    <div class="right">
                      <a
                        class="rate"
                        @click="click_reply_btn($event, { cur: ele })"
                        ><i class="iconfont icon-pinglun1"></i>
                      </a>
                      <a
                        class="dianzan iconfont"
                        @click="like_btn_click($event, ele, 4)"
                        ><i class="iconfont" :class="like_btn_style(ele)"> </i>
                        &nbsp;<span v-if="ele.like_cnt > 0">{{
                          ele.like_cnt
                        }}</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ----- -->
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/babel">
import { getUserInfo, get_token } from "../common/common.js";
export default {
  data() {
    return {
      reply_content: "",
      // comment_data: { nick_name: "zhangsan" },
      // is_active: true,
    };
  },
  props: {
    comment_data: { type: Object, required: true },
    // close_box: { type: Function, required: true },
    // open_reply_box: { type: Function, required: true },
    // close_reply_box: { type: Function, required: false },
  },
  methods: {
    get_cur_user() {
      // console.log("ddsgdg : ", getUserInfo(), this.cur_circle_data);
      return getUserInfo();
    },

    click_reply_btn(e, comment_date) {
      // console.log("点击hand组件reply", comment_date);
      this.$emit("get_friend_cycle_index", comment_date.target_id);
      this.$emit("click_reply_btn", comment_date);
      // this.click_comment_data = comment_date;
      // console.log("click reply btn ", this.click_comment_data);
      // this.is_replyBox_active = true;
      // this.$refs.
    },
    close_box() {
      // console.log("触发close_box");
      this.$emit("close_child_comment_box");
    },
    // 点赞
    async like_btn_click(e, item, type) {
      // console.log("点赞前：", item);

      // console.log("like_btn: ", item, type, {
      //   nick_name: this.get_cur_user().nick_name,
      //   target_id: item.id,
      //   type: type,
      //   is_delete: 1,
      // });
      // console.log("执行点赞前: ", item, item != null);
      // let item = this.cur_circle_data.children[index];
      // console.log("点赞前：", item);
      if (item != null) {
        if (item.is_like) {
          const data = {
            nick_name: this.get_cur_user().nick_name,
            target_id: "id" in item ? item.id : item.comment_id,
            type: type,
            is_delete: 1,
          };
          // console.log("发送取消点赞请求~~~~~~~~~~~~~~~~~~~~");
          const res = await this.send_dislike_request(data);
          // console.log("res:   dddd ", res, await res);
          if (res != null) {
            item.like_cnt--;
          } else {
            return;
          }
        } else {
          const data = {
            nick_name: this.get_cur_user().nick_name,
            target_id: "id" in item ? item.id : item.comment_id,
            type: type,
            is_delete: 0,
          };
          // console.log("发送点赞请求~~~~~~~~~~~~~~~~~~~~");
          const res = await this.send_like_request(data);
          // console.log("res:   dddd ", res, res.length);
          if (res != null) {
            item.like_cnt++;
          } else {
            return;
          }
        }
        item.is_like = !item.is_like;

        // if (item != null) {
        //   if (item.is_like) {
        //     item.like_cnt--;
        //   } else {
        //     item.like_cnt++;
        //   }
        //   item.is_like = !item.is_like;
      }
    },

    //发送点赞请求
    async send_like_request(data) {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/like", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    // 发送取消点赞请求
    async send_dislike_request(data) {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/dislike", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/dislike",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //     // data,
        //   }
        // );

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("评论接口返回结果response: friend ", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        // const res = response.data;
        // this.cur_circle_data.children.unshift({
        //   comment_id: res.comment_id,
        //   target_id: res.target_id,
        //   content: res.content,
        //   publish_time: res.create_time,
        //   nick_name: res.nick_name,
        //   avatar_url: res.avatar_url,
        //   child_comment_cnt: res.child_comment_cnt,
        //   like_cnt: res.like_cnt,
        //   is_like: res.is_like,
        // });
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    like_btn_style(item) {
      // console.log("执行计算属性:style: ", item.is_like);
      if (item.is_like) {
        return "icon-dianzan1";
      } else {
        return "icon-dianzan";
      }
    },
    // add_reply() {
    //   console.log(
    //     "添加回复成功: 添加的回复是 ",
    //     this.reply_content,
    //     this.comment_data
    //   );
    //   this.reply_content = "";
    //   this.$emit("add_reply", this.reply_content);
    // },
    // close_box(e) {
    //   console.log("关闭弹窗", e);
    //   this.$emit("close_reply_box");
    //   // this.is_active = false;
    //   // this.$refs.replybox.style.display = "none";
    // },
  },

  computed: {
    cur_comment_data: {
      get() {
        // console.log("触发计算属性,", this.comment_data);
        return this.comment_data;
      },
      set(newValue, oldValue) {
        // console.log("触发set: ", newValue, oldValue);
        this.cur_comment_data = newValue;
        // console.log("触发计算属性set,", this.cur_comment_data);
      },
    },
  },

  watch: {
    // comment_data: {
    //   handler(newValue, oldValue) {
    //     this.cur_comment_data = newValue;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
};
</script>

<style scoped>
.child-comment {
  width: 100%;
  height: 100%;
}
.comment-replybox {
  width: 100%;
  height: 100%;
}

.bg {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}

.content {
  margin: 200px auto;
  background-color: white;
  width: 600px;
  /* height: 100%; */
  min-height: 300px;
  height: 400px;
  overflow: auto;
  /* height: 600px; */
}

.comment-block {
  padding: 12px 5px;
}
.divider-line {
  /* border: 1px solid black; */
}

.box-interact {
  display: inline-block;
  position: relative;
  float: right;
  margin: 15px 20px 0px 0px;
}

.box-interact > i {
  font-size: 25px;
}

.comment-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin: 10px 0px; */
}

.comment-item .commener_img {
  width: 60px;
  border-radius: 30px;
}

.comment-item > .comment-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* align-items: center; */
}

.comment-item > .comment-content > .text {
  display: inline-block;
  text-align: left;
  margin: 3px 10px;
}
.comment-item .commener {
  color: orange;
}
.comment-item .commen_text {
  display: inline-block;
}

.comment-interactive {
  position: relative;
  width: 100%;
  height: 20px;
  /* display: flex;
  justify-content: space-between; */
}

.comment-interactive > .left {
  position: absolute;
  left: 0px;
  /* width: 150px; */
  display: inline-block;
}

.comment-interactive > .right {
  position: absolute;
  right: 0px;
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.comment-interactive > .left > .time {
  margin-left: 10px;
}

.comment-interactive > .right > .rate,
.dianzan:hover {
  cursor: pointer;
}

.comment-child {
  /* margin: 6px 0px 2px 44px; */
  padding: 5px 0px 5px 44px;
  /* background-color: red; */
}

.comment-child > .comments {
  margin-bottom: 10px;
}

.statis-text {
  color: black;
}
</style>
