<template>
  <div class="handmade-circle">
    <div class="content">
      <!-- 用户信息 -->
      <div class="user_identity">
        <div class="left-part">
          <img :src="cur_circle_data.avatar_url" alt="" />
        </div>
        <div class="right-part">
          <span>{{ cur_circle_data.nick_name }}</span>
          <span>{{ cur_circle_data.create_time }}</span>
        </div>
      </div>
      <!-- 文本描述 -->
      <div class="text_desc">
        <span>{{ cur_circle_data.content }}</span>
      </div>
      <!-- 图片 -->
      <div v-if="!is_view_img" class="circle_img">
        <!-- 最多九宫格图片 -->
        <img
          class="img-item"
          v-lazy="item"
          alt=""
          v-for="(item, index) in cur_circle_data.pic_list"
          :key="index"
          @click="view_img($event, index)"
        />
      </div>

      <!-- 当查看图片时切换为图片放大，并且可以点击切换左右图片 -->
      <div v-if="is_view_img" class="view-img" @click="view_img">
        <!-- 左侧遮罩 -->
        <div
          v-if="cur_view_img_obj.img_index > 0"
          class="left-img-mask"
          @click="left_img($event, cur_view_img_obj.img_index - 1)"
        ></div>

        <!-- 最多九宫格图片 -->
        <img
          class="single-img"
          :src="cur_view_img_obj.img_url"
          alt="网络问题，图片无法加载"
        />
        <span class="cur-img-pos">{{
          cur_view_img_obj.img_index + 1 + " / " + cur_view_img_obj.img_num
        }}</span>
        <!-- 右侧遮罩 -->
        <div
          v-if="cur_view_img_obj.img_index < cur_view_img_obj.img_num - 1"
          class="right-img-mask"
          @click="right_img($event, cur_view_img_obj.img_index + 1)"
        ></div>
      </div>
      <!-- 评论内容 -->
      <div class="interactive">
        <!-- 点赞 -->
        <a class="like-btn" @click="like_btn_click($event, cur_circle_data, 1)">
          <i
            class="iconfont"
            :class="{
              'icon-dianzan': !cur_circle_data.is_like,
              'icon-dianzan1': cur_circle_data.is_like,
            }"
          ></i>
          {{
            interactive_data["dianzan"][cur_circle_data.is_like]
          }}&nbsp;<span>{{ "(" + cur_circle_data.like_cnt + ")" }}</span>
        </a>
        <span>|</span>
        <!-- 评论 -->
        <a class="rate-btn" @click="rate_btn_click($event)">
          <i class="iconfont icon-pinglun1"></i>
          {{ interactive_data["rate"]["desc"] }}&nbsp;<span>{{
            "(" + cur_circle_data.comment_cnt + ")"
          }}</span>
        </a>
        <span>|</span>
        <!-- 收藏 -->
        <a
          class="collect-btn"
          @click="collect_btn_click($event, cur_circle_data, 1)"
        >
          <i
            class="iconfont"
            :class="{
              'icon-shoucang7': !cur_circle_data.is_collect,
              'icon-shoucang8': cur_circle_data.is_collect,
            }"
          ></i
          >{{
            interactive_data["collect"][cur_circle_data.is_collect]
          }}&nbsp;<span>{{ "(" + cur_circle_data.collect_cnt + ")" }}</span>
        </a>
        <span>|</span>
        <!-- 分享 -->
        <a class="share-btn" @click="share_btn_click">
          <i class="iconfont icon-fenxiang"></i
          >{{ interactive_data["share"]["desc"] }}&nbsp;<span>{{
            "(" + cur_circle_data.share_cnt + ")"
          }}</span>
        </a>
      </div>

      <!-- 评论模块 提交一级评论 -->
      <div v-if="isComment" class="comment_model">
        <div class="my-comment">
          <div class="content">
            <div class="comment-bar">
              <img class="user-img" :src="get_cur_user().avatar_url" alt="" />
              <el-input
                class="us-input"
                type="textarea"
                placeholder="请输入想要评论的内容"
                v-model="comment_content"
                :autosize="{ minRows: 2, maxRows: 2 }"
                show-word-limit
                style="margin: 10px"
              >
              </el-input>
              <div v-if="cur_comment_content" class="comment-btn">
                <el-button type="primary" @click="submit_comment"
                  >评论</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            'children' in cur_circle_data && cur_circle_data.children.length > 0
          "
          class="cus-comments"
        >
          <!-- 分割线 -->
          <div class="divider-line"></div>
          <!-- 子评论信息 一级评论 -->
          <div
            class="comments"
            v-for="(ele, index) in cur_circle_data.children"
            :key="index"
          >
            <!-- 每一个子评论 -->
            <div class="test">
              <div class="comment-item">
                <!-- 头像 -->
                <img class="commener_img" :src="ele.avatar_url" alt="" />
                <!-- 评论主体 -->
                <div class="comment-content">
                  <div class="text">
                    <a class="commener">{{ ele.nick_name }}&nbsp;:&nbsp;</a>
                    {{ ele.content }}
                  </div>
                  <div class="comment-interactive">
                    <div class="left">
                      <span class="time">{{ ele.publish_time }}</span>
                    </div>
                    <div class="right">
                      <a
                        class="rate"
                        @click="click_reply_btn($event, { cur: ele }, index)"
                        ><i class="iconfont icon-pinglun1"></i>
                      </a>
                      <a
                        class="dianzan iconfont"
                        @click="like_btn_click($event, ele, 4)"
                        ><i class="iconfont" :class="like_btn_style(ele)"> </i>
                        &nbsp;<span v-if="ele.like_cnt > 0">{{
                          ele.like_cnt
                        }}</span></a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <!-- 二级及更多级评论 -->
              <div
                v-if="'children' in ele && ele.children.length > 0"
                class="comment-child"
                v-for="(elem, index2) in ele.children"
              >
                <!-- <div class="child-item"> -->
                <!-- 头像 -->
                <img class="commener_img" :src="elem.avatar_url" alt="" />
                <!-- 评论主体 -->
                <div class="comment-content">
                  <div class="text">
                    <a class="commener"
                      >{{ elem.nick_name }}
                      <span v-if="elem.is_other_reply">
                        &nbsp;&nbsp;<span class="statis-text">{{
                          "回复" + ""
                        }}</span>
                        &nbsp;&nbsp;{{ elem.parent_nick_name }}</span
                      >&nbsp;:&nbsp;</a
                    >
                    {{ elem.content }}
                  </div>
                  <div class="comment-interactive">
                    <div class="left">
                      <span class="time">{{ elem.publish_time }}</span>
                    </div>
                    <div class="right">
                      <a
                        class="rate"
                        @click="
                          click_reply_btn(
                            $event,
                            { cur: elem, par: ele },
                            index
                          )
                        "
                        ><i class="iconfont icon-pinglun1"></i>
                      </a>
                      <a
                        class="dianzan iconfont"
                        @click="like_btn_click($event, elem, 4)"
                        ><i class="iconfont" :class="like_btn_style(elem)"> </i>
                        &nbsp;<span v-if="elem.like_cnt > 0">{{
                          elem.like_cnt
                        }}</span></a
                      >
                    </div>
                  </div>
                </div>
                <!-- </div> -->
              </div>
              <a
                href=""
                v-if="ele.child_comment_cnt > 0"
                class="more-reply"
                @click="view_replay($event, ele)"
                ><span>还有{{ ele.child_comment_cnt }}条回复</span></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Login v-if="!login_status" ref="login"></Login>
  </div>
</template>

<script type="text/babel">
import Vue from "vue";
import { getUserInfo, get_token,isLoggedIn } from "../common/common.js";
import CommentReplyBox from "./CommentReplyBox.vue";
import CONSTANT from "../config/constant.js";
import Login from "./Login.vue";
export default {
  name: "handmadeCircle",
  data() {
    return {
      scrollInterval: null,
      is_view_img: false, // 是否查看照片
      cur_view_img_obj: {
        img_url: CONSTANT.OSS_BASE_URL + "default_avator/30.jpg",
        img_index: 0,
        img_num: 1,
      },
      is_dianzan_active: false,
      is_collect_active: false,
      interactive_data: {
        dianzan: { true: "已赞", false: "点赞" },
        collect: { true: "已收藏", false: "收藏" },
        rate: { desc: "评论" },
        share: { desc: "分享" },
      },
      comment_content: "",
      click_comment_data: {},
      isComment: false,
      is_replyBox_active: false,
      comment_index: 0,
      // cur_circle_data: {},
      // cur_user_obj: {},
    };
  },

  components: { CommentReplyBox, Login },
  props: ["cur_user_obj"],
  methods: {

    // 点赞
    async like_btn_click(e, item, type) {
      this.login_btn_click_event();
      // console.log("like_btn: ", item, type, {
      //   nick_name: this.get_cur_user().nick_name,
      //   target_id: item.id,
      //   type: type,
      //   is_delete: 1,
      // });
      // console.log("执行点赞前: ", item, item != null);
      // let item = this.cur_circle_data.children[index];
      // console.log("点赞前：", item);
      if (item != null) {
        if (item.is_like) {
          const data = {
            nick_name: this.get_cur_user().nick_name,
            target_id: "id" in item ? item.id : item.comment_id,
            type: type,
            is_delete: 1,
          };
          // console.log("发送取消点赞请求~~~~~~~~~~~~~~~~~~~~");
          const res = await this.send_dislike_request(data);
          // console.log("res:   dddd ", res, await res);
          if (res != null) {
            item.like_cnt--;
          } else {
            return;
          }
        } else {
          const data = {
            nick_name: this.get_cur_user().nick_name,
            target_id: "id" in item ? item.id : item.comment_id,
            type: type,
            is_delete: 0,
          };
          // console.log("发送点赞请求~~~~~~~~~~~~~~~~~~~~");
          const res = await this.send_like_request(data);
          // console.log("res:   dddd ", res, res.length);
          if (res != null) {
            item.like_cnt++;
          } else {
            return;
          }
        }
        item.is_like = !item.is_like;
      }
      // this.cur_circle_data.children[index] = item;
      // this.$set(this.cur_circle_data.children, index, item);

      // console.log("点赞后", item);
      // this.is_dianzan_active = !this.is_dianzan_active;
      // if (this.is_dianzan_active) {
      //   this.user_obj.like_num++;
      // } else {
      //   this.user_obj.like_num--;
      // }
      // if (e.target.querySelector("i").class == "iconfont icon-dianzan") {
      //   e.target.querySelector("i").class = "iconfont icon-dianzan1";
      // } else {
      //   e.target.querySelector("i").class = "iconfont icon-dianzan";
      // }
      // console.log(e.target.querySelector("i")).class = "iconfont icon-dianzan";
    },

    //发送点赞请求
    async send_like_request(data) {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/like", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    // 发送取消点赞请求
    async send_dislike_request(data) {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/dislike", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/dislike",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //     // data,
        //   }
        // );

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("评论接口返回结果response: friend ", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        // const res = response.data;
        // this.cur_circle_data.children.unshift({
        //   comment_id: res.comment_id,
        //   target_id: res.target_id,
        //   content: res.content,
        //   publish_time: res.create_time,
        //   nick_name: res.nick_name,
        //   avatar_url: res.avatar_url,
        //   child_comment_cnt: res.child_comment_cnt,
        //   like_cnt: res.like_cnt,
        //   is_like: res.is_like,
        // });
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    // 发送收藏请求
    async send_collect_request(data) {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/collect", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/collect",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //     // data,
        //   }
        // );

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("评论接口返回结果response: friend ", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        // const res = response.data;
        // this.cur_circle_data.children.unshift({
        //   comment_id: res.comment_id,
        //   target_id: res.target_id,
        //   content: res.content,
        //   publish_time: res.create_time,
        //   nick_name: res.nick_name,
        //   avatar_url: res.avatar_url,
        //   child_comment_cnt: res.child_comment_cnt,
        //   like_cnt: res.like_cnt,
        //   is_like: res.is_like,
        // });
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },

    // 发送取消收藏请求
    async send_discollect_request(data) {
      try {
        const token = get_token("token");
        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/discollect", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: data,
          responseType: "json",
        });

        // var response = await this.$http.post(
        //   "http://www.soutudashi.com/discollect",
        //   {
        //     headers: { "Content-Type": "application/json" },
        //     data,
        //     // data,
        //   }
        // );

        // if (response.data.main.length == 0) {
        //   this.message_box("error", "该用户还没有在本站注册，请先注册");
        // } else {
        // console.log("评论接口返回结果response: friend ", response);

        // console.log("dddddddddsdfhdfhdf: ", response.data);

        // const res = response.data;
        // this.cur_circle_data.children.unshift({
        //   comment_id: res.comment_id,
        //   target_id: res.target_id,
        //   content: res.content,
        //   publish_time: res.create_time,
        //   nick_name: res.nick_name,
        //   avatar_url: res.avatar_url,
        //   child_comment_cnt: res.child_comment_cnt,
        //   like_cnt: res.like_cnt,
        //   is_like: res.is_like,
        // });
        return response.data;
        // }
      } catch (e) {
        console.log(e);
      } finally {
        // this.comment_content = "";
      }
    },
    view_replay(e, item) {
      e.preventDefault(); // 阻止默认行为
      // console.log("执行查看更多回复", item);
      this.$emit("view_replay", item);
    },

    like_btn_style(item) {
      // return "icon-dianzan1";
      // console.log("执行计算属性:style: ", item.is_like);
      if (item.is_like) {
        return "icon-dianzan1";
      } else {
        return "icon-dianzan";
      }
      // return this.cur_circle_data.children[index].is_like
      //   ? "icon-dianzan1"
      //   : "icon-dianzan";
    },

    message_box(type, message) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
        customClass: "messageIndex",
      });
    },
    // 评论
    async rate_btn_click(e) {
      // 点击前判断是否登录，没有登录则弹出登录框
      this.login_btn_click_event();
      // console.log("评论");
      try {
        const token = get_token("token");

        var response = await this.$http({
          method: "post",
          url: "http://www.soutudashi.com/get_comments_by_target", // 拼接URL和参数
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", // 设置跨域参数
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            Authorization: `Bearer ${token}`,
          },
          data: {
            type: "friend",
            id: this.cur_circle_data.id,
          },
          responseType: "json",
        });

        if (response.data.status != 200) {
          // this.message_box("error", "该用户还没有在本站注册，请先注册");
          this.isComment = !this.isComment;
        } else {
          if (this.cur_circle_data.children == null) {
            this.$set(this.cur_circle_data, "children", response.data.main);
            // this.cur_circle_data.children = response.data.main;
          } else {
            for (let i = 0; i < response.data.main.length; i++) {
              let flag = false;
              for (let y = 0; y < this.cur_circle_data.children.length; y++) {
                if (
                  this.cur_circle_data.children[y].comment_id ==
                  response.data.main[i].comment_id
                ) {
                  this.$set(
                    this.cur_circle_data.children,
                    y,
                    response.data.main[i]
                  );
                  // this.cur_circle_data.children[y] = response.data.main[i];
                  flag = true;
                  break;
                } else {
                  if (flag) {
                    this.cur_circle_data.children.push(response.data.main[i]);
                  }
                }
              }
            }
          }

          // console.log("当前的朋友圈数据", this.cur_circle_data);
          this.isComment = !this.isComment;

          return;
        }

        //   console.log(response);
      } catch (e) {
        console.log(e);
      }
      // this.user_obj.rate_num++;
    },

    // 收藏
    async collect_btn_click(e, item, type) {
      // 点击前判断是否登录，没有登录则弹出登录框
      this.login_btn_click_event();

      // console.log("收藏");
      item.is_collect = !item.is_collect;
      if (item.is_collect) {
        const data = {
          nick_name: this.get_cur_user().nick_name,
          target_id: "id" in item ? item.id : item.comment_id,
          type: type,
          is_delete: 0,
        };
        // console.log("发送点赞请求~~~~~~~~~~~~~~~~~~~~");
        const res = await this.send_collect_request(data);
        // console.log("res:   dddd ", res, await res);
        if (res != null) {
          this.cur_circle_data.collect_cnt++;
        }
      } else {
        const data = {
          nick_name: this.get_cur_user().nick_name,
          target_id: "id" in item ? item.id : item.comment_id,
          type: type,
          is_delete: 1,
        };
        // console.log("发送取消点赞请求~~~~~~~~~~~~~~~~~~~~");
        const res = await this.send_discollect_request(data);
        if (res != null) {
          this.cur_circle_data.collect_cnt--;
        }
      }
    },

    // 分享
    share_btn_click(e) {
      // 点击前判断是否登录，没有登录则弹出登录框
      // console.log("分享");
      // this.user_obj.share_num++;
    },

    view_img(e, index) {
      this.is_view_img = !this.is_view_img;
      this.cur_view_img_obj = {
        img_url: e.target.src,
        img_index: index,
        img_num: this.cur_circle_data.pic_list.length,
      };

      // console.log("is_view_img", this.is_view_img, this.cur_view_img_obj);
    },

    left_img(e, index) {
      e.stopPropagation(); // 阻止事件冒泡
      this.cur_view_img_obj = {
        img_url: this.cur_circle_data.pic_list[index],
        img_index: index,
        img_num: this.cur_circle_data.pic_list.length,
      };
    },

    right_img(e, index) {
      e.stopPropagation(); // 阻止事件冒泡
      this.cur_view_img_obj = {
        img_url: this.cur_circle_data.pic_list[index],
        img_index: index,
        img_num: this.cur_circle_data.pic_list.length,
      };
    },

    get_cur_user() {
      // console.log("ddsgdg : ", getUserInfo(), this.cur_circle_data);
      return getUserInfo();
    },

    // 提交并清空评论内容
    async submit_comment(e) {
      if (this.cur_comment_content != "") {
        // console.log("当前的curcircle: ", this.cur_circle_data);
        const data = {
          content: this.cur_comment_content,
          rate_type: "friend",
          target_id: this.cur_circle_data.id,
          nick_name: this.get_cur_user().nick_name,
          parent_id: 0,
        };

        // console.log("data对象: ", data);
        try {
          const token = get_token("token");
          var response = await this.$http({
            method: "put",
            url: "http://www.soutudashi.com/add_comment", // 拼接URL和参数
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*", // 设置跨域参数
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
              Authorization: `Bearer ${token}`,
            },
            data: data,
            responseType: "json",
          });

          // var response = await this.$http.put(
          //   "http://www.soutudashi.com/add_comment",
          //   {
          //     headers: { "Content-Type": "application/json" },
          //     data,
          //     // data,
          //   }
          // );

          // if (response.data.main.length == 0) {
          //   this.message_box("error", "该用户还没有在本站注册，请先注册");
          // } else {
          // console.log("评论接口返回结果response: friend ", response);

          // console.log("dddddddddsdfhdfhdf: ", response.data);

          if (response.data.status == 200) {
            this.$emit("add_reply", response.data.data);

            const res = response.data.data;
            // console.log("dddddd: ", this.get_cur_user);

            if (!("children" in this.cur_circle_data)) {
              this.$set(this.cur_circle_data, "children", []);
            }
            this.cur_circle_data.children.unshift({
              comment_id: res.comment_id,
              target_id: res.target_id,
              content: res.content,
              publish_time: res.publish_time,
              nick_name: res.nick_name,
              avatar_url: res.avatar_url,
              child_comment_cnt: res.child_comment_cnt,
              like_cnt: res.like_cnt,
              is_like: res.is_like,
            });
            return;
          } else {
            console.log("添加评论失败");
          }
          // }
        } catch (e) {
          console.log(e);
        } finally {
          this.comment_content = "";
        }
      }
    },

    click_reply_btn(e, comment_date, index) {
      // console.log(
      //   "点击hand组件reply",
      //   this.cur_circle_data,
      //   this.cur_circle_data.id,
      //   comment_date
      // );

      // const data = "par" in comment_date ? comment_date.par : comment_date.cur;
      this.comment_index = index;
      this.$emit("get_friend_cycle_index", this.cur_circle_data.id);
      this.$emit("click_reply_btn", comment_date);
      // this.click_comment_data = comment_date;
      // console.log("click reply btn ", this.click_comment_data);
      // this.is_replyBox_active = true;
      // this.$refs.
    },

    open_reply_box() {
      this.is_replyBox_active = true;
    },

    close_reply_box() {
      // console.log("父组件:close_reply_box");
      this.is_replyBox_active = false;
    },

    // 点击后弹出登录框
    login_btn_click_event() {
      if (!this.login_status) {
        // console.log("执行点击登录是的公积金都是固定时间间隔d~~~~~~~~~~~~~~");
        this.$nextTick(() => {
          this.$refs.login.click_login_btn();
        });
        return;
      }
    }, // 添加回复
    // add_replys(reply_body) {
    //   console.log(
    //     "添加回复圣诞节发士大夫大师傅",
    //     this.cur_circle_data,
    //     this.comment_index,
    //     this.cur_circle_data.children[this.comment_index]
    //   );
    //   if ("children" in this.cur_circle_data.children[this.comment_index]) {
    //     console.log(
    //       "unshift:    手动阀手动阀: ",
    //       this.cur_circle_data.children[this.comment_index]
    //     );
    //     this.cur_circle_data.children[this.comment_index].children.unshift(
    //       reply_body
    //     );
    //   } else {
    //     this.$set(
    //       this.cur_circle_data.children[this.comment_index],
    //       "children",
    //       [reply_body]
    //     );
    //   }

    //   console.log(
    //     "添加回复之后生成的数组: ",
    //     this.cur_circle_data.children[this.comment_index]
    //   );
    // },
  },

  computed: {
    cur_comment_content() {
      // console.log("res: ", this.cur_circle_data);
      return this.comment_content.trim();
    },
    login_status() {
      // return this.$store.state.is_login;
      // console.log("朋友圈登录状态: ",isLoggedIn())
      return isLoggedIn();
    },

    // hover_left_image() {
    //   console.log("this.hover_left_image: ", CONSTANT.HOVER_LEFT_IMAGE);
    //   return CONSTANT.HOVER_LEFT_IMAGE;
    // },

    // hover_right_image() {
    //   console.log("this.hover_right_image: ", CONSTANT.HOVER_RIGHT_IMAGE);
    //   return CONSTANT.HOVER_RIGHT_IMAGE;
    // },
    cur_circle_data: {
      get() {
        // console.log("触发计算属性,", this.cur_user_obj);
        return this.cur_user_obj;
      },
      set(newValue, oldValue) {
        // console.log("触发set: ", newValue, oldValue);
        this.cur_circle_data = newValue;
        // console.log("触发计算属性set,", this.cur_circle_data);
      },
    },
  },

  mounted() {
    // this.get_friend_cicle_list();
    // console.log("子组件: ", this.cur_user_obj);
    // this.cur_circle_data = this.cur_user_obj;
  },

  // watch: {
  //   cur_circle_data: {
  //     handler(newValue, oldValue) {
  //       console.log("监测到userobj发生变化了,", newValue, oldValue);
  //       this.cur_circle_data = newValue;
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
};
</script>

<style scoped>
.handmade-circle {
  width: 100%;
}

.content {
  width: 600px;
  margin: 10px auto;
}

.content .user_identity {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content .user_identity .left-part {
  /* flex: 0.5; */
}

.content .user_identity .left-part img {
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
.content .user_identity .right-part {
  /* flex: 1.2; */
  /* display: flex;
  justify-content: flex-start;
  flex-direction: column; */
  /* align-items: center; */
  margin-left: 20px;
  height: 100%;
}

.content .user_identity .right-part > span {
  font-size: 15px;
}
.content .user_identity .right-part > span:first-child {
  padding-top: 5px;
  text-align: left;
  display: block;
}

.content .user_identity .right-part > span:last-child {
  padding-top: 13px;
  text-align: left;
  display: block;
}

.content .text_desc {
  text-align: left;
}
/* 朋友圈文字左对齐 */
.content .text_desc span {
  margin: 10px auto;
  display: inline-block;
  text-align: left;
}

.content > .circle_img {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content > .circle_img > .img-item {
  width: 194px;
  height: 194px;
  margin: 3px;
}
::selection {
  background: transparent;
}

::-moz-selection {
  background: transparent;
}

::-webkit-selection {
  background: transparent;
}

.content .interactive {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 40px;
  background-color: #cccccc;
}

.interactive > .like-btn,
.collect-btn,
.share-btn,
.rate-btn :hover {
  cursor: pointer;
}

.content > .interactive > a.like-btn,
a.collect-btn,
a.rate-btn,
a.share-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(22%);
}

.content > .interactive > a > i {
  font-size: 22px;
}

.view-img {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 3px 0px;
}

.single-img {
  width: 600px;
  height: 100%;
}

.cur-img-pos {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 20px;
  color: black;
  font: bold;
}
.left-img-mask {
  position: absolute;
  width: 150px;
  height: 100%;
  top: 0;
  left: 0;
  /* background-color: green; */
  cursor: url("http://www.soutudashi.com/get_image/http%3A%2F%2Fimage.soutudashi.com%2Fstatis%2Fleft.ico"),
    default;
  z-index: 5;
}

.right-img-mask {
  top: 0;
  right: 0;
  position: absolute;
  /* cursor: var("--right-imag"); */
  cursor: url("http://www.soutudashi.com/get_image/http%3A%2F%2Fimage.soutudashi.com%2Fstatis%2Fright.ico"),
    default;
  width: 150px;
  height: 100%;
  /* background-color: green; */
  z-index: 5;
}

.right-img-mask:hover {
  cursor: url("http://www.soutudashi.com/get_image/http%3A%2F%2Fimage.soutudashi.com%2Fstatis%2Fright.ico"),
    default;
}
.left-img-mask:hover {
  cursor: url("http://www.soutudashi.com/get_image/http%3A%2F%2Fimage.soutudashi.com%2Fstatis%2Fleft.ico"),
    default;
}

/* 评论模块样式 */
.comment_model {
  width: 100%;
  height: auto;
}

.comment-bar {
  display: flex;
  justify-content: center;
  height: 100px;
  align-items: center;
}

.comment-bar > .user-img {
  height: 60px;
  border-radius: 30px;
}

.comment-bar > .us-input {
  line-height: 60px;
}

.divider-line {
  border: 1px solid black;
}

.comment-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
}

.comment-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
  padding-left: 44px;
}

.more-reply {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0px 0px 70px;
}
.comment-item .commener_img,
.comment-child .commener_img {
  width: 60px;
  border-radius: 30px;
}

.comment-item > .comment-content,
.comment-child > .comment-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* align-items: center; */
}

.comment-item > .comment-content > .text,
.comment-child > .comment-content > .text {
  display: inline-block;
  text-align: left;
  margin: 3px 10px;
}
.comment-item .commener,
.comment-child .commener {
  color: orange;
}
.comment-item .commen_text,
.comment-child .commen_text {
  display: inline-block;
}

.comment-interactive {
  position: relative;
  width: 100%;
  /* display: flex;
  justify-content: space-between; */
}

.comment-interactive > .left {
  position: absolute;
  left: 0px;
  /* width: 150px; */
  display: inline-block;
}

.comment-interactive > .right {
  position: absolute;
  right: 0px;
  width: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.comment-interactive > .left > .time {
  margin-left: 10px;
}

.comment-interactive > .right > .rate,
.dianzan:hover {
  cursor: pointer;
}

.statis-text {
  color: black;
}

.messageIndex {
  z-index: 999999 !important;
}
/* .comment-interactive > .right span {
  font-size: 19px;
} */

/* .comment-interactive > .right > .rate {
  width: 100px;
}
.comment-interactive > .right > .dianzan {
  width: 100px;
} */
</style>
